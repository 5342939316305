.site {
  display: flex;
  align-items: center;
  padding: 20px;
  transition: 0.15s all linear;

  &:nth-child(even) {
    background-color: rgba(14, 30, 37, 0.02);
  }

  .icon-go {
    margin-left: auto;
    order: 2;

    color: lightgray;
    transition: 0.15s all linear;
  }

  &:hover {
    .icon-go {
      color: hsla(0, 0%, 0%, 0.97);
    }

    background-color: rgba(14, 30, 37, 0.05);
  }
}

.screenshot {
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  height: 4.5em;
  width: 6.48em;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 1em;

  &::before {
    background: #0e1e24;
    border-radius: 4px;
    bottom: 0;
    content: ' ';
    left: 0;
    opacity: 0.2;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
  }
}
